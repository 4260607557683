import { Header } from 'components/Header';
import { useMenu } from 'hooks/useMenu';
import { NightModeButton } from 'components/NightModeButton';
import { MenuItem } from './MenuItem';
import { ProfileList } from './ProfileList';
import { Menu, Spliter } from './index.styles';

export const HeaderLayout = () => {
  const { isMenuOpen } = useMenu();
  return (
    <Header>
      <Menu data-isopen={isMenuOpen}>
        <MenuItem href="/games">Spis gier</MenuItem>
        <MenuItem href="/categories/mmo-na-telefon">Mobilne gry</MenuItem>
        <MenuItem href="https://forum.mmorpg.org.pl/">Forum</MenuItem>
        <MenuItem href="/szukaj">Szukaj</MenuItem>
        <MenuItem href="https://tally.so/r/w7xL09">Kontakt</MenuItem>
        <Spliter />
        <ProfileList />
        <MenuItem>
          <NightModeButton />
        </MenuItem>
      </Menu>
    </Header>
  );
};

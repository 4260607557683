import NightModeButtonIcon from 'components/Icons/NightModeButton';
import { useTheme } from 'hooks/useTheme';
import styled from 'styled-components';

export const Wrapper = styled.button`
  border: none;
  background: none;
  width: 35px;
  height: 35px;
  padding: 0;
  margin: 15px;
`;

export const NightModeButton = () => {
  const { toggleTheme } = useTheme();
  return (
    <Wrapper type="button" onClick={toggleTheme} aria-label="change theme">
      <NightModeButtonIcon />
    </Wrapper>
  );
};

import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" viewBox="0 0 35 35">
      <circle cx="17.5" cy="17.5" r="16.5" fill="#1D2332" stroke="#fff" strokeWidth="2" />
      <mask id="mask0" width="21" height="21" x="8" y="7" maskUnits="userSpaceOnUse">
        <circle cx="18.5" cy="17.5" r="10.5" fill="#1D2332" />
      </mask>
      <g fill="#fff" mask="url(#mask0)">
        <path d="M8 7H29V8H8z" />
        <path d="M8 10H29V11H8z" />
        <path d="M8 13H29V14H8z" />
        <path d="M8 16H29V17H8z" />
        <path d="M8 19H29V20H8z" />
        <path d="M8 22H29V23H8z" />
        <path d="M8 25H29V26H8z" />
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M23.004 8.013a9.715 9.715 0 00-.166.01C17.604 8.39 14.634 13 15 18.233c.34 4.843 3.275 8.514 8.005 8.755a10.472 10.472 0 01-3.772.986c-5.785.405-10.802-3.957-11.207-9.742-.405-5.784 3.957-10.802 9.742-11.206 1.869-.131 3.657.236 5.236.987z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;

import styled from 'styled-components';

export const OuterWrapper = styled.footer`
  padding: 30px 5px;
  background-color: var(--bg-strong);
  width: 100%;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: var(--app-width);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  max-width: 200px;
`;

export const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
`;

export const TextWrapper = styled.div`
  color: var(--font);
  padding: 15px;
  font-size: 16px;
`;

import styled from 'styled-components';
import * as PopupStyles from 'components/Popup';

export const OuterWrapper = styled(PopupStyles.OuterWrapper)`
  padding: 10px;
`;

export const InnerWrapper = styled(PopupStyles.InnerWrapper)`
  padding: 30px 30px 150px;
  background-image: url('/background.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 120vw;

  @media (${(props) => props.theme.mediaQuery.desktop}) {
    background-size: 100%;
    overflow-y: visible;
  }
`;

export const Header = styled.h5`
  margin: 15px auto;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  span {
    color: #d13537;
  }

  @media (${(props) => props.theme.mediaQuery.desktop}) {
    margin: 40px auto;
    font-size: 25px;
    line-height: 31px;
    width: 750px;
  }
`;

export const Warn = styled.span`
  color: #d13537;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 6vw;
  line-height: 6vw;
  margin: 20px 0;

  svg {
    margin: 0 3vw;
    width: 10vw;
    height: 12vw;
  }

  @media (${(props) => props.theme.mediaQuery.desktop}) {
    font-size: 68px;
    line-height: 85px;

    svg {
      height: unset;
      width: unset;
      margin: 0 50px;
    }
  }
`;

export const Fizz = styled.img`
  position: absolute;
  bottom: 0;
  width: 220px;
  right: 20%;

  @media (${(props) => props.theme.mediaQuery.desktop}) {
    width: unset;
    right: 10%;
    bottom: -140px;
  }
`;

import Link from 'next/link';
import { Loading } from 'components/Loading';
import { useMenu } from 'hooks/useMenu';
import { Item } from './MenuItem.styles';

export const MenuItem = ({ href, onClick, children, loading, title, ...restProps }) => {
  const { setIsMenuOpen } = useMenu();

  if (loading) {
    return (
      <Item {...restProps}>
        <Loading />
      </Item>
    );
  }

  if (href) {
    return (
      <Link href={href} passHref>
        <Item as="a" title={title || children} onClick={() => setIsMenuOpen(false)} {...restProps}>
          {children}
        </Item>
      </Link>
    );
  }

  if (onClick) {
    return (
      <Item as="button" onClick={onClick} {...restProps}>
        {children}
      </Item>
    );
  }

  return <Item {...restProps}>{children}</Item>;
};

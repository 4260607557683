import styled from 'styled-components';
import { mediaQueryMenu } from 'utils/constants';

export const AvatarIcon = styled.div`
  width: 40px;
  flex-shrink: 0;
  height: 40px;
  border-radius: 40px;
  border: 2px solid var(--bg-medium);
  box-sizing: padding-box;
  box-shadow: 0 0 0 2px var(--font);
  overflow: hidden;
  padding: 0;
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  &[data-open='false'] {
    display: none;
  }
  & > * {
    justify-content: center;
    & > svg {
      margin-right: 10px;
    }
  }
  @media (min-width: ${mediaQueryMenu}) {
    width: 200px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    top: calc(100% + 5px);
    z-index: 10;
    background-color: var(--bg-strong);
    & > * {
      justify-content: flex-start;
    }
  }
`;

export const Wrapper = styled.div`
  @media (max-width: ${mediaQueryMenu}) {
    width: 100%;
  }
  @media (min-width: ${mediaQueryMenu}) {
    display: flex;
  }
  position: relative;
`;

export const RedBullet = styled.div`
  position: absolute;
  padding: 5px;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  line-height: 5px;
  font-size: 10px;
  background-color: red;
`;

import { useEffect } from 'react';
import { Footer } from 'components/Footer';
import { Adblock } from 'components/Adblock';
import Script from 'next/script';
import { useSession, useCachedData } from 'apis';
import { HeaderLayout } from './HeaderLayout';
import { Wrapper, Content, SideAdv } from './MainLayout.styles';

const SideNav = ({ image, url }) => {
  if (!url || !image) return null;
  return (
    <SideAdv href={url} rel="noopener noreferrer" target="_blank">
      <img src={image} alt="adv background" />
    </SideAdv>
  );
};

export const MainLayout = ({ children, background = false }) => {
  const { data: session, loading } = useSession();
  const isDisabled = session?.user?.disable_ads;

  const { data: advertisement } = useCachedData(
    { type: 'advertisement' },
    { initialData: { image: '', url: '#' }, enabled: !loading && !isDisabled }
  );

  useEffect(() => {
    document.documentElement.style.setProperty('--display-ads', isDisabled ? 'none' : 'flex');
  }, [isDisabled]);

  return (
    <>
      <Adblock>
        <HeaderLayout />
        <Wrapper data-background={background}>
          <SideNav {...advertisement} />
          <Content>{children}</Content>
          <SideNav {...advertisement} />
        </Wrapper>
        <Footer />
      </Adblock>
      {loading
        ? null
        : !isDisabled && (
            <>
              <Script src="https://lib.wtg-ads.com/publisher/mmorpg.org.pl/lib.min.js" async />
              <Script src="/scripts/ads1.js" async />
              <Script src="/scripts/tlo.js" async />
            </>
          )}
    </>
  );
};

import { Logo } from 'components/Logo';
import { SocialIcon } from './SocialIcon';
import { LogoWrapper, SocialsWrapper, TextWrapper, Wrapper, OuterWrapper } from './Footer.styles';

export const Footer = () => (
  <OuterWrapper>
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <SocialsWrapper>
        {/* <SocialIcon variant="twitch" href="https://www.twitch.tv/" /> */}
        <SocialIcon variant="facebook" href="https://www.facebook.com/mmoplaya" />
        <SocialIcon variant="twitter" href="https://twitter.com/grymmorpg" />
        <SocialIcon variant="discord" href="https://discord.com/invite/VfVGhNU" />
        <SocialIcon variant="youtube" href="https://www.youtube.com/user/TheMmorpgLive" />
        <SocialIcon variant="instagram" href="https://www.instagram.com/mmoplaya/" />
      </SocialsWrapper>
      <TextWrapper>© 2006 - {new Date().getFullYear()} MMORPG.org.pl</TextWrapper>
    </Wrapper>
  </OuterWrapper>
);

import { useState } from 'react';
import { useLoginPopup } from 'hooks/useLoginPopup';
import { useSession } from 'apis';
import Image from 'next/image';
import { Button } from 'components/Button';
import { FaChevronDown, FaChevronUp, FaUserCircle, FaBell } from 'react-icons/fa';
import { MdChat } from 'react-icons/md';
import { ImExit } from 'react-icons/im';
import { signOut } from 'next-auth/react';
import { useAlert } from 'react-alert';
import { MenuItem } from '../MenuItem';
import { AvatarIcon, WrapperList, Wrapper, RedBullet } from './index.styles';

export const ProfileList = () => {
  const [profileIsOpen, setProfileIsOpen] = useState(false);
  const { setLoginPopup } = useLoginPopup();
  const { data: session, isLoading, refetch } = useSession();
  const alert = useAlert();

  const handleSignOut = async () => {
    const result = await signOut({ redirect: false });

    if (result.error) {
      alert.error('Nie udało się wylogować');
    } else {
      await refetch();
      alert.success('Pomyślnie wylogowano');
    }
  };

  if (isLoading) {
    return <MenuItem loading disabled />;
  }

  if (!session?.user) {
    return (
      <MenuItem disabled>
        <Button onClick={() => setLoginPopup(true)}>Zaloguj się</Button>
      </MenuItem>
    );
  }
  const { avatar, newNotificationsCount, newMessagesCount } = session.user;

  return (
    <Wrapper>
      {!!(newNotificationsCount || newMessagesCount) && (
        <RedBullet>{newNotificationsCount + newMessagesCount}</RedBullet>
      )}
      <MenuItem onClick={() => setProfileIsOpen(!profileIsOpen)}>
        <AvatarIcon>
          <Image src={avatar} height={50} width={50} alt="User" objectFit="cover" objectPosition="center" />
        </AvatarIcon>
        &nbsp;
        {profileIsOpen ? <FaChevronUp /> : <FaChevronDown />}
      </MenuItem>
      <WrapperList data-open={profileIsOpen}>
        <MenuItem href="/profile" title="Profil">
          <FaUserCircle size={20} />
          Twój profil
        </MenuItem>
        <MenuItem href="/chat" title="Wiadomości">
          <MdChat size={20} />
          {!!newMessagesCount && <RedBullet>{newMessagesCount}</RedBullet>}
          Wiadomości
        </MenuItem>
        <MenuItem href="/notifications" title="Powiadomienia">
          <FaBell size={20} />
          Powiadomienia
          {!!newNotificationsCount && <RedBullet>{newNotificationsCount}</RedBullet>}
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ImExit size={20} />
          Wyloguj się
        </MenuItem>
      </WrapperList>
    </Wrapper>
  );
};

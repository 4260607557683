import styled from 'styled-components';
import { mediaQueryMenu } from 'utils/constants';

export const Item = styled.div`
  text-align: center;
  transition: background-color 0.4s;
  text-decoration: none;
  text-transform: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
  &:not([disabled]) {
    &:hover {
      background-color: var(--navbar-item-hover);
    }
  }
  @media (max-width: ${mediaQueryMenu}) {
    width: 100%;
    height: 60px;
    flex: none;
    &[data-margin='true'] {
      margin: 0;
    }
  }
`;

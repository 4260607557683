export const WEB_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.NEXT_PUBLIC_WEB_URL;

export const SEO_PROPS = {
  description:
    'Największy polski portal o grach MMO / MMORPG / MOBA / MMOFPS. Newsy, baza gier, materiały video i forum dyskusyjne. Zapraszamy fanów gier!.', // default description
  title: 'Darmowe MMORPG - spis gier MMO, MMOFPS, MMORPG 3d, MOBA',
  image: '/img/img_src.png',
  keywords: 'mmorpg,mmo,moba,gry online,gry mmo,tibia,wow,world of warcraft,online games',
  themeColor: '#212838',
  icon: '/favicon.ico',
  sitemap: '/sitemap.xml',
  robots: '/robots.txt',
  manifest: '/manifest.json',
  author: 'MMORPG',
};

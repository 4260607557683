import { IoLogoTwitch, IoLogoFacebook, IoLogoTwitter } from 'react-icons/io';
import { FaDiscord, FaYoutube, FaInstagram } from 'react-icons/fa';
import { WrapperLink } from './SocialIcon.styles';

const iconsArray = {
  twitch: <IoLogoTwitch />,
  facebook: <IoLogoFacebook />,
  twitter: <IoLogoTwitter />,
  discord: <FaDiscord />,
  youtube: <FaYoutube />,
  instagram: <FaInstagram />,
};

export const SocialIcon = ({ variant, href }) => (
  <WrapperLink href={href} variant={variant} title={variant}>
    {iconsArray[variant]}
  </WrapperLink>
);

import styled from 'styled-components';
import { mediaQueryMenu } from 'utils/constants';

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  height: 100%;
  @media (max-width: ${mediaQueryMenu}) {
    padding: 30px 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: fixed;
    z-index: 9999999;
    top: var(--header-height);
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--bg-strong);
    transition: transform 0.3s;
    transform: translateX(-100%);
    &[data-isopen='true'] {
      transform: translateX(0);
    }
  }
`;

export const Spliter = styled.div`
  flex: 1;
  @media (max-width: ${mediaQueryMenu}) {
    display: none;
  }
`;

import { useState, useEffect } from 'react';
import ShieldIcon from 'components/Icons/Shield';
import { Fizz, Header, OuterWrapper, Warn, InnerWrapper } from './Adblock.styles';

export const Adblock = ({ children }) => {
  const [isAdblock, setIsAdblock] = useState(false);

  useEffect(() => {
    if (/opera|opr/i.test(window.navigator.userAgent)) return;
    fetch('https://lib.wtg-ads.com/publisher/mmorpg.org.pl/lib.min.js').catch(() => {
      setIsAdblock(true);
    });
  }, []);

  if (!isAdblock) {
    return children;
  }

  return (
    <OuterWrapper>
      <InnerWrapper>
        <Header>
          Wykryliśmy u Ciebie blokowanie reklam. To smutne, bo ten portal utrzymuje się z emisji reklam. Aby kontynuować
          prosimy o wyłączenie wtyczek.
        </Header>
        <Warn>
          Wyłącz <ShieldIcon /> Adblocka
        </Warn>
        <Fizz src="/img/fizz.png" alt="AdBlock img" />
      </InnerWrapper>
    </OuterWrapper>
  );
};

import styled from 'styled-components';

const socialMediaBackgrounds = {
  twitch: '#8242E4',
  facebook: '#4671CC',
  twitter: '#55ACEE',
  discord: '#7289DA',
  youtube: '#FF0000',
  instagram: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 4%, #fd5949 30%,#d6249f 60%,#285AEB 90%)',
};

export const WrapperLink = styled.a`
  width: 48px;
  height: 48px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => socialMediaBackgrounds[props.variant]};
  transition: opacity 0.3s;
  font-size: 30px;
  color: white;
  &:hover {
    opacity: 0.6;
  }
`;

import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="134" height="164" fill="none" viewBox="0 0 134 164">
      <path
        fill="#D13537"
        d="M5.803 16l61-16v164s-48.053-29.898-61-61c-13.057-31.367 0-87 0-87zM127.803 16l-61-16v164s48.054-29.898 61-61c13.057-31.367 0-87 0-87z"
      />
      <path
        fill="#FFFCF5"
        fillRule="evenodd"
        d="M95.499 115L26 45.501 37.502 34 107 103.498 95.499 115z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFCF5"
        fillRule="evenodd"
        d="M107 45.502L37.501 115 26 103.498 95.498 34 107 45.502z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;

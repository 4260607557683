import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  margin: 20px auto;
  display: flex;
  @media (${(props) => props.theme.mediaQuery.desktop}) {
    &[data-background='true'] {
      background-image: url('/background-alt.png');
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 100% 60vh;
    }
  }
`;

export const Content = styled.div`
  width: var(--app-width);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const SideAdv = styled.a`
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { WEB_URL, SEO_PROPS } from './index.constants';

const setImage = (image) => (/^http/.test(image) ? image : WEB_URL + image);

export const Seo = ({ description, title, image, keywords }) => {
  const router = useRouter();

  const metaTitle = title ? `${title} | ${SEO_PROPS.title}` : SEO_PROPS.title;
  const metaImage = setImage(image || SEO_PROPS.image);
  const metaDescription = description || SEO_PROPS.description;
  const metaKeywords = keywords || SEO_PROPS.keywords;

  const fullUrl = WEB_URL + router.asPath;

  return (
    <Head>
      <title>{metaTitle}</title>

      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <link rel="image_src" href={metaImage} />

      <meta property="og:site_name" content={WEB_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:url" content={fullUrl} />

      <meta name="twitter:creator" content={SEO_PROPS.author} />
      <meta name="twitter:site" content={fullUrl} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
    </Head>
  );
};
